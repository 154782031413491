import { conform, useForm } from '@conform-to/react';
import { getFieldsetConstraint, parse } from '@conform-to/zod';
import { invariant } from '@epic-web/invariant';
import {
	json,
	redirect,
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node';
import {
	Form,
	useActionData,
	useLoaderData,
	useSearchParams,
} from '@remix-run/react';
import { AuthenticityTokenInput } from 'remix-utils/csrf/react';
import { HoneypotInputs } from 'remix-utils/honeypot/react';
import { safeRedirect } from 'remix-utils/safe-redirect';
import { z } from 'zod';

import { CheckboxField, ErrorList, Field } from '#app/components/forms.tsx';
import { StatusButton } from '#app/components/ui/status-button.tsx';
import {
	requireAnonymous,
	sessionKey,
	signup,
} from '#app/utils/auth.server.tsx';
import { redirectWithConfetti } from '#app/utils/confetti.server.ts';
import { validateCSRF } from '#app/utils/csrf.server.ts';
import { prisma } from '#app/utils/db.server.ts';
import { checkHoneypot } from '#app/utils/honeypot.server.ts';
import { buildMetaTitle } from '#app/utils/meta.ts';
import { getEzLogo, useIsPending } from '#app/utils/misc.tsx';
import { authSessionStorage } from '#app/utils/session.server.ts';
import {
	NameSchema,
	PasswordAndConfirmPasswordSchema,
	UsernameSchema,
} from '#app/utils/user-validation.ts';
import { verifySessionStorage } from '#app/utils/verification.server.ts';

import { type VerifyFunctionArgs } from './verify.tsx';

const onboardingEmailSessionKey = 'onboardingEmail';

const SignupFormSchema = z
	.object({
		username: UsernameSchema,
		name: NameSchema,
		/* agreeToTermsOfServiceAndPrivacyPolicy: z.boolean({
			required_error:
				'You must agree to the terms of service and privacy policy',
		}), */
		remember: z.boolean().optional(),
		redirectTo: z.string().optional(),
	})
	.and(PasswordAndConfirmPasswordSchema);

async function requireOnboardingEmail(request: Request) {
	await requireAnonymous(request);
	const verifySession = await verifySessionStorage.getSession(
		request.headers.get('cookie'),
	);
	const email = verifySession.get(onboardingEmailSessionKey);
	if (typeof email !== 'string' || !email) {
		throw redirect('/signup');
	}
	return email;
}
export async function loader({ request }: LoaderFunctionArgs) {
	const email = await requireOnboardingEmail(request);
	return json({ email });
}

export async function action({ request }: ActionFunctionArgs) {
	const email = await requireOnboardingEmail(request);
	const formData = await request.formData();
	await validateCSRF(formData, request.headers);
	checkHoneypot(formData);
	const submission = await parse(formData, {
		schema: intent =>
			SignupFormSchema.superRefine(async (data, ctx) => {
				const existingUser = await prisma.user.findUnique({
					where: { username: data.username },
					select: { id: true },
				});
				if (existingUser) {
					ctx.addIssue({
						path: ['username'],
						code: z.ZodIssueCode.custom,
						message: 'A user already exists with this username',
					});
					return;
				}
			}).transform(async data => {
				if (intent !== 'submit') return { ...data, session: null };

				const session = await signup({ ...data, email });
				return { ...data, session };
			}),
		async: true,
	});

	if (submission.intent !== 'submit') {
		return json({ status: 'idle', submission } as const);
	}
	if (!submission.value?.session) {
		return json({ status: 'error', submission } as const, { status: 400 });
	}

	const { session, remember, redirectTo } = submission.value;

	const authSession = await authSessionStorage.getSession(
		request.headers.get('cookie'),
	);
	authSession.set(sessionKey, session.id);
	const verifySession = await verifySessionStorage.getSession();
	const headers = new Headers();
	headers.append(
		'set-cookie',
		await authSessionStorage.commitSession(authSession, {
			expires: remember ? session.expirationDate : undefined,
		}),
	);
	headers.append(
		'set-cookie',
		await verifySessionStorage.destroySession(verifySession),
	);

	return redirectWithConfetti(safeRedirect(redirectTo), { headers });
}

export async function handleVerification({
	request,
	submission,
}: VerifyFunctionArgs) {
	invariant(submission.value, 'submission.value should be defined by now');
	const verifySession = await verifySessionStorage.getSession(
		request.headers.get('cookie'),
	);
	verifySession.set(onboardingEmailSessionKey, submission.value.target);
	return redirect('/onboarding', {
		headers: {
			'set-cookie': await verifySessionStorage.commitSession(verifySession),
		},
	});
}

export const meta: MetaFunction = () => {
	return [{ title: buildMetaTitle('Konto erstellen') }];
};

export default function SignupRoute() {
	const data = useLoaderData<typeof loader>();
	const actionData = useActionData<typeof action>();
	const isPending = useIsPending();
	const [searchParams] = useSearchParams();
	const redirectTo = searchParams.get('redirectTo');

	const [form, fields] = useForm({
		id: 'onboarding-form',
		constraint: getFieldsetConstraint(SignupFormSchema),
		defaultValue: { redirectTo, agreeToTermsOfServiceAndPrivacyPolicy: true },
		lastSubmission: actionData?.submission,
		onValidate({ formData }) {
			return parse(formData, { schema: SignupFormSchema });
		},
		shouldRevalidate: 'onBlur',
	});

	return (
		<div className="flex min-h-screen flex-col justify-center bg-gray-100 pb-32 pt-20">
			<div className="mx-auto w-full max-w-md rounded border bg-white py-4">
				<div className="mb-4 flex flex-col gap-3">
					<img
						src={getEzLogo()}
						alt="MeinTraktor Logo"
						className="ml-auto w-[60%] pr-8"
					/>
					<h1 className="pl-8 text-h5" style={{ marginTop: '-1.5em' }}>
						Konto erstellen
					</h1>
					<p className="pl-8 text-body-sm">für {data.email}</p>
				</div>
				<div className="mx-auto mt-4 min-w-full max-w-sm sm:min-w-[368px]">
					<Form
						method="POST"
						className="mx-auto min-w-[368px] max-w-sm"
						{...form.props}
					>
						<AuthenticityTokenInput />
						<HoneypotInputs />
						<Field
							labelProps={{
								htmlFor: fields.username.id,
								children: 'Benutzername',
							}}
							inputProps={{
								...conform.input(fields.username),
								autoComplete: 'username',
								className: 'lowercase',
							}}
							errors={fields.username.errors}
						/>
						<Field
							labelProps={{ htmlFor: fields.name.id, children: 'Name' }}
							inputProps={{
								...conform.input(fields.name),
								autoComplete: 'name',
							}}
							errors={fields.name.errors}
						/>
						<Field
							labelProps={{ htmlFor: fields.password.id, children: 'Passwort' }}
							inputProps={{
								...conform.input(fields.password, { type: 'password' }),
								autoComplete: 'new-password',
							}}
							errors={fields.password.errors}
						/>

						<Field
							labelProps={{
								htmlFor: fields.confirmPassword.id,
								children: 'Passwort bestätigen',
							}}
							inputProps={{
								...conform.input(fields.confirmPassword, { type: 'password' }),
								autoComplete: 'new-password',
							}}
							errors={fields.confirmPassword.errors}
						/>

						{/* <CheckboxField
						labelProps={{
							htmlFor: fields.agreeToTermsOfServiceAndPrivacyPolicy.id,
							children:
								'Do you agree to our Terms of Service and Privacy Policy?',
						}}
						buttonProps={conform.input(
							fields.agreeToTermsOfServiceAndPrivacyPolicy,
							{ type: 'checkbox' },
						)}
						errors={fields.agreeToTermsOfServiceAndPrivacyPolicy.errors}
					/> */}
						<CheckboxField
							labelProps={{
								htmlFor: fields.remember.id,
								children: 'Angemeldet bleiben',
							}}
							buttonProps={conform.input(fields.remember, { type: 'checkbox' })}
							errors={fields.remember.errors}
						/>

						<input {...conform.input(fields.redirectTo, { type: 'hidden' })} />
						<ErrorList errors={form.errors} id={form.errorId} />

						<div className="flex items-center justify-between gap-6">
							<StatusButton
								className="w-full"
								status={isPending ? 'pending' : actionData?.status ?? 'idle'}
								type="submit"
								disabled={isPending}
							>
								Konto erstellen
							</StatusButton>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}
